<template>
<v-container fluid class="pa-0 ma-0">
<v-card style="z-index:1000;" light class="elevation-0">
<v-app-bar flat color="white">
    <v-toolbar-title><v-icon class="mr-3 " color="success" >mdi-cogs</v-icon> Filtre général</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn text class="pl-0" @click="clearFilter"><v-icon :color="(filterCount) ? 'red' : 'grey'" title="Réinitialiser mon filtre">mdi-refresh</v-icon></v-btn>
    <v-btn text :class="(filterCount) ? 'red--text' : ''" @click="saveFilter" title="Enregistrer le filtre">
        <v-icon :color="(filterCount) ? 'green' : 'grey'">mdi-content-save</v-icon>
    </v-btn>
</v-app-bar>
<v-divider></v-divider>
<v-layout row wrap class="ma-0 pl-2 pr-2 pb-4">
    <v-flex xs6 class="pl-2 pr-2">
        <DatePicker label="Date action" :objectForm="dateMenu" fieldName='min_tsk_date_plan'></DatePicker>
    </v-flex>
    <v-flex xs6 class="pl-2 pr-2">
        <DatePicker label="Date de fin action" :objectForm="dateMenu" fieldName='max_tsk_date_plan'></DatePicker>
    </v-flex>
    <v-flex xs6 class="pa-0 pr-2" v-if="!loading.getItem">
        <v-subheader inset>Statut</v-subheader>
        <template v-for="(item, index) in filterSource.eve_sta">
            <v-alert border="left" class="ma-0 mb-1 pa-0" dense :colored-border="( filter.eve_sta_id.includes(item.sta_id) ? false : true )"
                :color="item.sta_color" elevation="1" :key="index">
                <v-row align="center" >
                    <v-col class="pa-1 pl-6 grow">{{ item.sta_label }}</v-col>
                    <v-col class="pa-1 pr-4 shrink"><v-switch v-model="filter.eve_sta_id" :value="item.sta_id" color="white"></v-switch></v-col> <!-- :color="item.sta_color" -->
                </v-row>        
            </v-alert>
        </template>
    </v-flex>
    <v-flex xs6 class="pa-0">
        <v-autocomplete dense clearable class="mt-5"
            v-model="filter.eve_typ_id" :items="filterSource.eve_typ" label="Type de tache" 
            item-text="typ_name" item-value="typ_id" multiple chips  @change="updateFilter('eve_typ_id')">
            <template v-slot:selection="data">
                <v-chip :input-value="data.selected" @click:close="removeChip('eve_typ_id', data.item.typ_id)" 
                        close small class="chip--select-multi" :color="data.item.typ_color" >
                    {{ data.item.typ_name }}
                </v-chip>
            </template>
            <template slot="item" slot-scope="data">
                <span :style="'color: ' + data.item.typ_color + ' !important'">{{ data.item.typ_name }}</span>
            </template>
            <template v-slot:no-data>
                Aucune donnée
            </template>
        </v-autocomplete>
    </v-flex>
</v-layout>
<v-card-actions>
    <v-spacer></v-spacer>
    <v-btn small rounded color="success" @click="closeWindow()">Appliquer</v-btn>
</v-card-actions>
</v-card>
</v-container>
</template>

<script>
import DatePicker from '@/components/ui/DatePicker'
import { cacheGetters, cacheMutations, cacheActions } from "../store/cache.module"
import { filterUpdateService } from '@/rxjsServices';
//import autoCompMulti    from './Autocomplete_multi.vue'

export default {
    name: 'view-filter',
    props: ['filterDiff', 'filterCount', 'filterView'],
components: { DatePicker },
    data: function () {
        return {
            saveProgress: false,
            loading: { getItem: true },
            typSit: [],
            menu: false,
            dateMenu: { tsk_date_plan: false, tsk_date_end: false },
            filter: {
                tag_id: [],
                eve_sta_id: [],
                eve_typ_id: [],
                min_tsk_date_plan: null, 
                max_tsk_date_plan: null
            },
            filterSource:{
                eve_sta: [],
                eve_typ: [],
                tag: [],
                typ: [],
            },
            typSource: [],

            filterTab : [],
            sitTab : [],
            storeFilter: {},
            eveState: this.getCacheState('GEN')
        }
    },
    watch: {
        filterView () {
            cacheMutations.setFilter(this.filter)
            this.updateFilter()
        }
    },
    beforeMount: function(){
        this.initFilter()
    },
    mounted:function (){
        
    },
    methods: {
        initFilter(){
            this.storeFilter = JSON.parse( JSON.stringify( this.filter ) ) // todoo init if empty
            this.filterSource.eve_typ = cacheGetters.getTypes( 'ACT' )
            this.filterSource.eve_sta = this.getCacheState('GEN')
            this.loading.getItem = false
        },
        updateFilter(){
            var total = 0
            var diff  = []
            for( let prop in this.filter ){
                if( Array.isArray( this.filter[prop] ) ){
                    diff = this.storeFilter[prop].filter(x => this.filter[prop].indexOf(x) < 0 )
                    total += diff.length
                    diff = this.filter[prop].filter(x => this.storeFilter[prop].indexOf(x) < 0 )
                    total += diff.length
                }
            }
            this.$emit('update:filterCount', total)
        },
        removeItem (item) {
            let index = this.filter.equ_typ_id.indexOf(item.sit_id)
            if (index >= 0) { 
                this.filter.equ_typ_id.splice(index, 1)
            }
        },
        saveFilter(){
            cacheMutations.setFilter( this.filter )
            cacheActions.storeCacheToStorage()
            this.storeFilter = JSON.parse( JSON.stringify( this.filter ) )
        },
        clearFilter(){
            var filter      = cacheGetters.getFiltrerFromStorage()
            for(var prop in filter){
                if( Object.prototype.toString.call( this.filter[prop] ) === '[object Array]' ) {
                    this.filter[prop] = filter[prop].slice( this.filter[prop] )
                } else if( this.filter[prop] !== undefined ){
                    this.filter[prop] = filter[prop]
                }
            }
            this.updateFilter()
        },
        closeWindow(){
            cacheMutations.setFilter( this.filter )
            this.$emit('update:filterView', false)
            filterUpdateService.sendFilter('change')
        },
        removeChip (fromFilter, id) {
            const index = this.filter[fromFilter].indexOf(id)
            if (index >= 0) this.filter[fromFilter].splice(index, 1)
        },
        getCacheState: cacheGetters.getStates,
        getCacheType: cacheGetters.getTypes,
    }
}
</script>