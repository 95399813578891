<template>
    <v-card fluid class="ma-2 pa-1 elevation-2" color="grey lighten-4">
        <v-card-text class="pr-0">
        <v-layout row wrap class="ma-0 mt-0">
            <v-flex xs12 class="pb-0" align-start>
                <v-card class="" style="border-bottom-left-radius: 40px;">
                    <v-flex>
                        <v-text-field label="Description" v-model="form.phy_title"></v-text-field>
                    </v-flex>
                    <v-flex>
                        <DatePicker label="Date" :objectForm="form" fieldName='phy_date'></DatePicker>
                    </v-flex>
                    <template v-for="(item, index) in this.form.phy_custom_fields">
                        <v-flex v-if="item.ctp_data_type == 'VARCHAR'" xs12 class="pr-2 pl-2" :key="index">
                            <v-text-field :label="item.ctp_label" v-model="item.cdd_value" ></v-text-field>
                        </v-flex>
                        <v-flex v-else-if="item.ctp_data_type == 'INT'" xs12 class="pr-2 pl-2" :key="index">
                            <v-text-field :label="item.ctp_label" v-model="item.cdd_value" type="number"></v-text-field>
                        </v-flex>
                        <v-flex v-else-if="item.ctp_data_type == 'FLOAT'" xs12 class="pr-2 pl-2" :key="index">
                            <v-text-field :label="item.ctp_label" v-model="item.cdd_value" type="number"></v-text-field>
                        </v-flex>
                        <v-flex v-else-if="item.ctp_data_type == 'BOOL'" xs12 class="pr-2 pl-2" :key="index">
                            <v-checkbox :label="item.ctp_label" v-model="item.cdd_value" ></v-checkbox>
                        </v-flex>
                        <v-flex v-else-if="item.ctp_data_type == 'LIST'" xs12 class="pr-2 pl-2" :key="index">
                            <v-select style="z-index: 2000;"
                                :items="listsData[item.ctp_lst_id]" item-value="lsd_id" item-text="lsd_label"
                                v-model="item.cdd_lsd_id" :label="item.ctp_label" @change="listChange(item, index)">
                            </v-select> <!-- single-line -->
                        </v-flex>
                        <v-flex v-else-if="item.ctp_data_type == 'PERCENT'" xs12 class="pr-2 pl-2" :key="index">
                            <v-text-field :label="item.ctp_label" v-model="item.cdd_value" ></v-text-field>
                        </v-flex>
                    </template>
                </v-card>
            </v-flex>
        </v-layout>
        </v-card-text>
        <div class="text-right">
            <v-btn color="success" :loading="saveProgress" @click.native="saveForm()" :disabled="saveProgress">
            Enregistrer
            </v-btn>
            <v-btn color="primmary" @click.native="switchModal('modalItem', 0)">
            Retour
            </v-btn>
        </div>
    </v-card>
</template>

<script>
import { cacheGetters } from "@/store/cache.module"
import DatePicker from '@/components/ui/DatePicker'

export default {
    name: 'edit_phyto',
    props: ['toast', 'switchModal', 'cad_id', 'itemId'],
    components: { DatePicker },
    data: () => ({
        saveProgress: false,         // Enregistrement en cours...
        dialog: false,
        form:{
            phy_id       : 0,
            phy_cad_id   : 0,
            phy_sta_id   : 'GEN01',
            phy_typ_id   : 'PHY01',
            phy_title    : '',
            phy_date     : null,
            phy_custom_fields : [],
        }, 
        typPanel: 0,
        listsData: {},
    }),
    beforeMount: function(){
        this.typInfo = cacheGetters.getTypes('CTP')
        this.listsData   = this.getListsDataByLstId()
    },
    mounted:function (){
        this.form.phy_cad_id = this.cad_id
        this.form.phy_id     = this.itemId
        this.getForm()
    },
    methods: {
        async getForm(){
            if( this.itemId !== undefined && this.itemId ){
                await this.getItem()
            } else {
                await this.getFields()
            }
        },
        getFields(){
            return new Promise( (resolve) => {
                this.$http.get( '/cardtemplates/?per_page=false&order_by=ctp_pos_row&order_direction=asc&extended=phyto' ).then( (response) => {
                    this.form.phy_custom_fields = response.data.data
                    resolve('resolve')
                })
            })
        },
        getItem(){
            return new Promise( (resolve) => {
                this.$http.get( '/phyto/' + this.form.phy_id ).then( (response) => {
                    this.form = response.data.data
                    resolve('resolve')
                })
            })
        },
        saveForm () {
            // Enregistrement du formulaire
            this.saveProgress = true
            if( this.form.phy_id ){
                this.$http.put( '/phyto/' + this.form.phy_id, this.form )
                .then( () => {
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.switchModal('modalItem', 0, 1)
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                });
            } else {
                this.$http.post( '/phyto/', this.form )
                .then( () => {
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.switchModal('modalItem', 0, 1)
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                });
            }
        },
        listChange(item, index){
            let listDataObj = this.listsData[item.ctp_lst_id].find(obj => obj.lsd_id === item.cdd_lsd_id)
            this.form.phy_custom_fields[index].cdd_value = listDataObj.lsd_label
            if( listDataObj.lsd_lsd_id_link > 0 ){
                let lidDataLinked = this.allDataList.find(obj => obj.lsd_id === listDataObj.lsd_lsd_id_link)
                this.form.phy_custom_fields.forEach(element => {
                    if( lidDataLinked && (element.ctp_lst_id === lidDataLinked.lsd_lst_id)){
                        element.cdd_lsd_id = lidDataLinked.lsd_id
                    }
                })
            }
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates,
        getListsDataByLstId: cacheGetters.getListsDataByLstId,
    }
}
</script>